<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center px-2 my-2">
          <ion-buttons slot="start">
            <ion-back-button
              color="primary"
              mode="ios"
              text=""
              default-href="/sale/notifications"
              @click="$router.back()"
              :icon="chevronBackOutline"
            ></ion-back-button>
          </ion-buttons>
          <ion-label class="fw-600 pl-1 fs-3" @click="$router.back()"
            >{{ $t('orders_and_invoices_notification') }}
            <ion-note v-if="badgeHeader" class="fs-1 ml-1 badge">{{ badgeHeader }}</ion-note>
          </ion-label>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <pull-refresh v-if="!isOnline"></pull-refresh>
      <skeleton-notifications v-if="isLoading" />
      <div v-else>
        <comp-not-found style="margin-top: 5rem" v-if="notificationsList?.length <= 0" :useAction="true" />
        <div v-else>
          <comp-mark-all-as-read />

          <card-notification v-for="(notif, idx) in notificationsList || []" :key="idx" :data="notif" />
        </div>
      </div>
      <ion-infinite-scroll
        @ionInfinite="loadMore($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabledLoadMore || notificationsList?.length <= 0"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>
<script>
import CardNotification from '@/modules/shared/components/notifications/CardNotification.vue';
import CompMarkAllAsRead from '@/modules/shared/components/notifications/CompMarkAllAsRead.vue';
import CompNotFound from '@/modules/shared/components/notifications/NotFoundNotification.vue';
import SkeletonNotifications from '@/modules/shared/components/notifications/SkeletonNotifications.vue';

import { usePullToRefreshPage } from '@/usecases/global';
import { useOrderAndInvoiceNotification } from '@/usecases/notifications';
import { defineAsyncComponent, defineComponent } from 'vue';
export default defineComponent({
  name: 'SaleNotifOrderAndInvoicesList',
  components: {
    CardNotification,
    CompNotFound,
    SkeletonNotifications,
    CompMarkAllAsRead,
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },
  setup() {
    const {
      loadMore,
      fetchData,
      notificationsList,
      badgeHeader,
      isLoading,
      chevronBackOutline,
      isDisabledLoadMore
    } = useOrderAndInvoiceNotification();
    const { isOnline, handleRefreshAndRefetchData } = usePullToRefreshPage();
    const handleRefresh = (event) => {
      handleRefreshAndRefetchData(event, fetchData);
    };
    return {
      chevronBackOutline,
      isDisabledLoadMore,
      badgeHeader,
      isLoading,
      loadMore,
      isOnline,
      notificationsList,
      handleRefresh
    };
  }
});
</script>
<style lang="scss" scoped>
.badge {
  background-color: var(--ion-color-danger);
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 600;
  margin: auto;
  padding: 3px 5px;
}
</style>
